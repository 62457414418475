import React, { useEffect, useState } from "react";
import { ClubLogo } from "../../ClubLogo";
import { PlayerImage } from "../../PlayerImg";

const MarketDisplay = ({ i, market, matchingMarketPrice, onSelectionClick, isBetSlip=false, selectionId=-1, closeModal=null }) => {

    return (
        <div key={i}>
            {matchingMarketPrice && (
                <>
                <div className={`${isBetSlip ? ('my-0 mx-0') : ('my-[10px] mx-[15px] md:mx-0')}`}>
                    
                    <div className='bg-lime border border-solid border-main h-[30px] font-anton text-[14px] p-[8px] pt-[5px] text-main flex'>
                        <div className='flex-grow'>
                            {market.name}
                        </div>
                        {isBetSlip && (
                            <div className='pt-[3px] text-right cursor-pointer' onClick={() => closeModal()}>
                                <svg 
                                    fill="#1B062F" 
                                    height="14px" 
                                    width="14px" 
                                    viewBox="0 0 460.775 460.775"
                                >
                                    <path d="M285.08,230.397L456.218,59.27c6.076-6.077,6.076-15.911,0-21.986L423.511,4.565c-2.913-2.911-6.866-4.55-10.992-4.55
                                        c-4.127,0-8.08,1.639-10.993,4.55l-171.138,171.14L59.25,4.565c-2.913-2.911-6.866-4.55-10.993-4.55
                                        c-4.126,0-8.08,1.639-10.992,4.55L4.558,37.284c-6.077,6.075-6.077,15.909,0,21.986l171.138,171.128L4.575,401.505
                                        c-6.074,6.077-6.074,15.911,0,21.986l32.709,32.719c2.911,2.911,6.865,4.55,10.992,4.55c4.127,0,8.08-1.639,10.994-4.55
                                        l171.117-171.12l171.118,171.12c2.913,2.911,6.866,4.55,10.993,4.55c4.128,0,8.081-1.639,10.992-4.55l32.709-32.719
                                        c6.074-6.075,6.074-15.909,0-21.986L285.08,230.397z"/>
                                </svg>
                            </div>
                        )}
                    </div>


                    <div className='bg-white border border-solid border-main'>

                        {market.team!=='Both' && (
                            <div className='p-[10px] flex'>
                                <div>
                                    <ClubLogo teamName={market.team} width={'37px'} height={'37px'} />
                                </div>
                                <div className='font-anton font-normal capitalize text-[18px] pt-[9px] pl-[5px] text-main'>
                                    {market.team} {market.market_type} 
                                </div>
                            </div>
                        )}
                        <div className='flex flex-wrap gap-5 p-[10px]'>

                            {matchingMarketPrice.selections.map((selection, index) => (
                                <>
                                {market.class==='Win' ? (
                                    <div 
                                        className={`
                                            border border-solid border-main 
                                            ${market.selections_n<3 ? 'w-[calc(50%-10px)]' : 'w-[calc(33%-15px)]'} 
                                            text-center font-chakra text-main text-[14px]
                                            ${isBetSlip && selection.id===selectionId && 'bg-lightgreen'}
                                            `}
                                    >
                                        {market.market_type=='Next Player Out' ? (
                                            <div className='mt-[10px] flex justify-center'>
                                                <PlayerImage teamName={market.team} playerName={selection.name} width={'60px'} height={'60px'} />
                                            </div>
                                        ) : market.market_type.includes('Match Winner') ? (
                                            <div className='mt-[10px] flex justify-center'><ClubLogo teamName={selection.name} width={'60px'} height={'60px'} /></div>
                                        ) : (
                                            null
                                        )}
                                        <div className='mt-[10px] normal-case'>{selection.name}</div>
                                        <div 
                                            className={`my-[10px] font-bold bg-marketBlue ${matchingMarketPrice.status!=='ACTIVE' ? 'bg-marketYellow' : 'cursor-pointer '} p-[6px] px-[12px] inline-block`}
                                            onClick={() => {
                                                if (matchingMarketPrice.status==='ACTIVE') {
                                                    onSelectionClick(i, selection.id, market.market_id)
                                                }
                                            }}
                                        >
                                            {selection.recommended_price}
                                        </div>
                                    </div>
                                ) : market.class==='Line' ? (
                                    <div 
                                        className={`
                                            border border-solid border-main flex-grow text-center font-chakra text-main text-[14px]
                                            ${isBetSlip && selection.id===selectionId && 'bg-lightgreen'}
                                            `}>
                                        {market.market_type=='Next Player Out' ? (
                                            <>
                                                <div className='mt-[10px]'>
                                                    <PlayerImage teamName={teams} playerName="{selection.name}" width='50px' height='50px' />
                                                </div>
                                                <div className='mt-[10px] normal-case'>z{selection.name}</div>
                                            </>
                                        ) : (
                                            <>
                                                <div className='mt-[10px] normal-case'>{selection.name} {parseFloat(matchingMarketPrice.line).toFixed(1)}</div>
                                            </>
                                        )}
                                        <div 
                                            className={`my-[10px] font-bold ${selection.name === "Over" ? 'bg-marketBlue' : 'bg-marketPink'} ${matchingMarketPrice.status!=='ACTIVE' && 'bg-marketYellow'} p-[6px] px-[12px] inline-block cursor-pointer`}
                                            onClick={() => onSelectionClick(i, selection.id)}
                                        >
                                            {selection.recommended_price}
                                        </div>
                                    </div>
                                ) : ( null )}
                                </>
                            ))}
                        </div>
                    </div>
                </div>
                </>
            )}

        </div>
    );

};

export default MarketDisplay;

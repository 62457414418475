import React, { useEffect, useState } from "react";
import TraderMatchCard from "./trader-match-card";

const UpcomingMatches = ({ maxMatches=3 }) => {
    const [fixtureList, setFixtureList] = useState([]);
    useEffect(() => {
        const fetchFixtures = async () => {
            let tournamentFilter = process.env.TOURNAMENT.toLowerCase();
            let maxDisplay=20;
            const res = await fetch(`${process.env.API_URL}/livefixturelist/${maxMatches}`, {
                method: 'GET', 
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': process.env.API_KEY
                },
            });
            const data = await res.json();
            const mData=data.matches;
            setFixtureList(mData);
        }
        fetchFixtures();
    }, []);

    return (
        <>
            <div className='mx-[15px] md:flex md:flex-wrap gap-3 md:justify-center'>
                {fixtureList.map((fixture, i) => 
                    <TraderMatchCard key={i} fixture={fixture} />
                )}
            </div>
        </>
    );
};

export default UpcomingMatches;
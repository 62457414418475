import React, { useEffect, useState } from "react";
import { ClubLogo } from "../../ClubLogo";
import { useUser } from "../../../UserContext";
import Modal from 'react-modal';

const TraderMatchCard = ({ fixture }) => {
    const { user, setUser } = useUser();
    const [liveBat, setLiveBat] = useState('');
    const [score, setScore] = useState(['-', '-', '-', '-']);
    const [loginModal, setLoginModal] = useState(false);

    const matchParts = fixture.name.split(',');
    const matchTitle = matchParts.length >= 3 ? matchParts[1].trim() : '';
    const teams = matchParts[0].split(" v ");
    const status = fixture.status.split(" - ");

    const [matchStart, setMatchStart] = useState("");

    let matchstatus=status[0].replace(/\s*\(.*?\)\s*/g, '').trim();
    if (fixture.status.includes('Match in Progress')) { matchstatus='IN-PLAY'; }

    const wsUrl = process.env.FEED_URL; 
    const apiKey = process.env.DECIMAL_API_KEY;

    async function getLiveScore(match_id, teams) {
        let socket;
        if (process.env.ENVIRONMENT!=='dev') {
          socket = new WebSocket(`${wsUrl}`);
        } else {
          socket = new WebSocket(`${wsUrl}/${match_id}/${apiKey}/APIFEED-${match_id}`);
        }
        socket.addEventListener('open', function open() {
          const message = JSON.stringify({
            feed_filter: ['scorecard','match_update'],
            msg_type: "feed_subscription", 
            feed_id: match_id,
          });
          socket.send(message);
        });
      
        socket.addEventListener('message', function incoming(data) {
          const parsedData = JSON.parse(data.data);
            if (parsedData.match_update) {
                const targetTime = new Date(parsedData.match_update.start_datetime).getTime();
                const currentTime = new Date().getTime();
                const difference = targetTime - currentTime;
                if (difference <= 0) { setMatchStart(""); };
                const hours = String(Math.floor(difference / (1000 * 60 * 60))).padStart(2, "0");
                const minutes = String(Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, "0");
                const seconds = String(Math.floor((difference % (1000 * 60)) / 1000)).padStart(2, "0");
                setMatchStart(hours+"H "+minutes+"M");
            }
            if (parsedData.scorecard) {
            let s1="0/0", s2="0/0", s3="0/0", s4="0/0";
            if (parsedData.scorecard.inns1!==null) {
                s1=parsedData.scorecard.inns1.runs+"/"+parsedData.scorecard.inns1.wkts;
            }
            if (parsedData.scorecard.inns2!==null) {
                s2=parsedData.scorecard.inns2.runs+"/"+parsedData.scorecard.inns2.wkts;
            }
            if (parsedData.scorecard.inns3!==null) {
                s3=parsedData.scorecard.inns3.runs+"/"+parsedData.scorecard.inns3.wkts;
            }
            if (parsedData.scorecard.inns4!==null) {
                s4=parsedData.scorecard.inns4.runs+"/"+parsedData.scorecard.inns4.wkts;
            }
            let insScore=[s1,s2,s3,s4];
            let innsnow=parsedData.scorecard.inns_now;
            let oppnow=1;
            let rptNow=2;
            if (innsnow===1 || innsnow===3) { 
                oppnow=2; 
                rptNow=1;
            }
            setLiveBat(parsedData.scorecard.bat_now);
            if (parsedData.scorecard.bat_now===teams[0]) {
                setScore([insScore[rptNow-1], insScore[oppnow-1], insScore[rptNow+1], insScore[oppnow+1]]);
            } else {
                setScore([insScore[oppnow-1], insScore[rptNow-1], insScore[oppnow+1], insScore[rptNow+1]]);
            }
          }
        });
        socket.addEventListener('error', function error(error) {
          //console.error('WebSocket error:', error);
        });
        socket.addEventListener('close', function close(event) {
          //console.log('Disconnected from the WebSocket server:', event);
          //console.log(`Close code: ${event.code}, reason: ${event.reason}`);
        });
    }

    useEffect(() => {
        getLiveScore(fixture.id, teams);
    }, [fixture.id, teams]);


    return (
        <>
        <div className='my-[15px] mb-[30px] bg-mainH p-[7px] md:w-[32%]'>
            <div className='font-chakra text-white normal-case flex gap-2'>
                <div className='flex-grow font-[12px] truncate text-ellipsis'>{matchTitle}</div>
                <div className={`flex-end font-[12px] text-nowrap ${matchstatus==='IN-PLAY' && ('text-pink')}`}>{matchstatus}</div>
            </div>
            <div className='relative flex gap-5 mt-[20px]'>
                <div className='relative bg-main60 h-[60px] w-full flex'>
                    <div className='bg-white h-[60px] w-[60px] flex items-center justify-center'>
                        <ClubLogo teamName={teams[0]} height={'50px'} width={'50px'} />
                    </div>
                    <div className='flex-grow text-center pt-[7px]'>
                        <div className='font-chakra font-bold font-[20px]'>
                            {fixture.abbrTeams.home}
                        </div>
                        <div className='font-chakra font-normal font-[16px] opacity-[50%]'>
                            {score[2]!=="0/0" && score[2]!=='-' ? (
                                <><span className='text-[0.8em] opacity-[0.7]'>{score[0].split("/")[0]} &amp;</span> {score[2]}</>
                            ) : (
                                <>{score[0]}</>
                            )}
                        </div>
                    </div>
                    {liveBat===teams[0] && (
                        <div className='absolute bottom-[0px] h-[3px] w-full bg-pink' />
                    )}
                </div>
                <div className='relative bg-main60 h-[60px] w-full flex'>
                    <div className='flex-grow text-center pt-[7px]'>
                        <div className='font-chakra font-bold font-[20px]'>
                            {fixture.abbrTeams.away}
                        </div>
                        <div className='font-chakra font-normal font-[16px] opacity-[50%]'>
                            {score[3]!=="0/0" && score[3]!=='-' ? (
                                <><span className='text-[0.8em] opacity-[0.7]'>{score[1].split("/")[0]} &amp;</span> {score[3]}</>
                            ) : (
                                <>{score[1]}</>
                            )}
                        </div>
                    </div>
                    <div className='bg-white h-[60px] w-[60px] flex items-center justify-center'>
                        <ClubLogo teamName={teams[1]} height={'50px'} width={'50px'} />
                    </div>
                    {liveBat===teams[1] && (
                        <div className='absolute bottom-[0px] h-[3px] w-full bg-pink' />
                    )}
                </div>
                <div className='absolute top-[15px] left-[calc(50%_-_15px)] -translate-x-[calc(50%_-_15px)] w-[30px] h-[30px] bg-lime text-main font-anton text-[14px] flex items-center justify-center'>
                    VS
                </div>
            </div>

            {matchstatus!=='Match Scheduled' ? (
                <div 
                    className='mt-[10px] h-[40px] w-full bg-lime hover:bg-limehover text-main font-anton text-[16px] pt-[9px] text-center cursor-pointer'
                    onClick={() => { 
                        if (user) {    
                            location.href="/trader?match="+fixture.id; 
                        } else {
                            setLoginModal(true);
                        }
                    }}
                >
                    PLAY TRADER
                </div>
            ) : ( 
                <div className='bg-main h-[40px] text-lime mt-[10px] w-full font-anton text-[16px] text-center pt-[12px]'>
                    STARTS IN {matchStart}
                </div>
            )}

        </div>


        <Modal 
                isOpen={loginModal} 
                onRequestClose={() => setLoginModal(false)} 
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: '100%',
                        maxWidth: '350px',
                        maxHeight: '290px', 
                        minHeight: '290px',
                        background: '#1b062f',
                        textAlign: 'left',
                        color: '#fff',
                        padding: '0',
                        overflowY: 'auto'
                    }
                }}
                contentLabel="Sign up"
                ariaHideApp={false}
            >
                <div className='w-full bg-modal'>
                    <div className='relative flex justify-center'>
                        <div className='fit-content text-center bg-white text-main font-anton text-[14px] p-[5px]'>
                            LOGIN OR SIGN UP
                        </div>
                        <div className='absolute right-[5px] top-[3px] text-right cursor-pointer' onClick={() => setLoginModal(false)}>
                            <svg 
                                fill="#fff" 
                                height="10px" 
                                width="10px" 
                                viewBox="0 0 460.775 460.775"
                            >
                                <path d="M285.08,230.397L456.218,59.27c6.076-6.077,6.076-15.911,0-21.986L423.511,4.565c-2.913-2.911-6.866-4.55-10.992-4.55
                                    c-4.127,0-8.08,1.639-10.993,4.55l-171.138,171.14L59.25,4.565c-2.913-2.911-6.866-4.55-10.993-4.55
                                    c-4.126,0-8.08,1.639-10.992,4.55L4.558,37.284c-6.077,6.075-6.077,15.909,0,21.986l171.138,171.128L4.575,401.505
                                    c-6.074,6.077-6.074,15.911,0,21.986l32.709,32.719c2.911,2.911,6.865,4.55,10.992,4.55c4.127,0,8.08-1.639,10.994-4.55
                                    l171.117-171.12l171.118,171.12c2.913,2.911,6.866,4.55,10.993,4.55c4.128,0,8.081-1.639,10.992-4.55l32.709-32.719
                                    c6.074-6.075,6.074-15.909,0-21.986L285.08,230.397z"/>
                            </svg>
                        </div>
                    </div>
                    <div className='mt-[20px] font-chakra text-white case-normal text-[14px] text-center'>You need an active Cricket8 account<br/>to play trader.</div>
                    <div className='flex gap-2 p-[10px]'>
                        <div 
                            className='w-1/2 bg-lime hover:bg-limehover cursor-pointer font-anton text-main text-[14px] text-center py-[13px]'
                            onClick={() => location.href='/login' }
                        >LOGIN</div>
                        <div 
                            className='w-1/2 bg-lime hover:bg-limehover cursor-pointer font-anton text-main text-[14px] text-center py-[13px]'
                            onClick={() => location.href='/signup' }
                        >SIGNUP</div>
                    </div>
                    <div className='m-[10px] bg-mainH p-[10px] flex gap-5'>
                        <div><img height='60px' src={`${process.env.CDN_ENDPOINT}/assets/coinsx4.png`} /></div>
                        <div className='flex-grow font-anton text-[14px] text-white text-center leading-[18px] pt-[10px]'>GET 100 FREE COINS WHEN<br/>YOU CREATE AN ACCOUNT!</div>
                        <div><img height='60px' src={`${process.env.CDN_ENDPOINT}/assets/coinsx3.png`} /></div>
                    </div>
                    <div className='h-[10px] w-full' />
                </div>
            </Modal>
            </>
    );
};

export default TraderMatchCard;

import React, { useState, useEffect } from "react";
import ClubLogo from "../../ClubLogo";
import PlayerImage from "../../PlayerImg";
import { Cookies } from "react-cookie-consent"
import { motion } from "framer-motion";

const MyTrades = () => {
    const [dateRange, setDateRange] = useState("Today");
    const [tradeStatus, setTradeStatus] = useState("Open & Settled");
    const [trades, setTrades] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);
    const [tradesPerPage, setTradesPerPage] = useState(10);
    const [pagination, setPagination] = useState([1,2,3]);

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        const options = {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: false, 
        };
        return date.toLocaleString("en-GB", options).replace(",", " -");
    };
   
    const generateCoins = () => {
        return Array.from({ length: 20 }).map((_, index) => {
            const randomXEnd = Math.random() * 400 - 200; 
            return (
                <motion.img
                    key={index}
                    src={`${process.env.CDN_ENDPOINT}/assets/single_coin.png`}
                    className="absolute h-[30px]"
                    initial={{ opacity: 1, y: 0, x: 0, scale: 1.0 }}
                    animate={{ opacity: 0, y: -100, x: randomXEnd, scale: 5.0 }}
                    transition={{ 
                        duration: 3, 
                        ease: "easeOut", 
                        delay: index * 0.2  
                    }}
                    style={{ left: '0px', bottom: '0px', transform: "translate(-50%, -50%)" }}
                />
            );
        });
    };
        

    const getTrades = async(page=1) => {
        const session_token = Cookies.get('c8_session_token');
        const headers = {
            'Content-Type': 'application/json',
        };
        headers['x-api-key'] = process.env.API_KEY;
        const response = await fetch(`${process.env.API_URL}/trade/mytrades/${session_token}/${dateRange}/${tradeStatus}/${page}/${tradesPerPage}`, {
            method: 'get',
            headers: headers
        });
        if (response.ok) {
            const myTrades = await response.json();
            setTrades(myTrades.trades);
            let mp=Math.ceil(myTrades.count/tradesPerPage);
            if (mp<1 || isNaN(mp)) { mp=1; };
            setMaxPage(mp);
            let x=page;
            if (x>1) { x=x-1; }
            setPagination([x,x+1,x+2]);
        }
    };

    useEffect(() => {
        getTrades(currentPage);
    }, [dateRange, tradeStatus, currentPage, tradesPerPage]);

    return (
        <div>
            <div className="w-full flex gap-2">
                <div className='w-[50%]'>
                    <div className='font-chakra text-[#18062f] !text-left text-[12px] md:text-[14px]'>
                        <div className="select-wrapper !w-[100%]">
                            <select
                                id="daterange"
                                name="daterange"
                                className="dropdown !h-[35px]"
                                value={dateRange}
                                onChange={(e) => {
                                    setDateRange(e.target.value);
                                    setCurrentPage(1);
                                }}
                            >
                                <option>Today</option>
                                <option>Yesterday</option>
                                <option>All</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className='w-[50%]'>
                    <div className='font-chakra text-[#18062f] !text-left text-[12px] md:text-[14px]'>
                        <div className="select-wrapper !w-[100%]">
                            <select
                                id="selectopen"
                                name="selectopen"
                                className="dropdown !h-[35px]"
                                value={tradeStatus}
                                onChange={(e) => {
                                    setTradeStatus(e.target.value);
                                    setCurrentPage(1);
                                }}
                            >
                                <option>Open & Settled</option>
                                <option>Open Only</option>
                                <option>Settled Only</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <div className='md:flex md:flex-wrap w-full md:gap-3 md:justify-center'>

                {trades.map((trade, i) => {
                    const matchParts = trade.name.split(',');
                    const matchTitle = matchParts.length >= 3 ? matchParts[1].trim() : '';
                    const teams = matchParts[0].split(" v ");

                    let settledStatus='OPEN';
                    if (trade.settled===true) {
                        if (trade.marketdetails.selectionname==='Under') {
                            if (parseFloat(trade.outcome)<parseFloat(trade.marketdetails.marketline)) {
                                settledStatus='WIN';
                            } else {
                                settledStatus='LOST';
                            }
                        } else if (trade.marketdetails.selectionname==='Over') {
                            if (parseFloat(trade.outcome)>parseFloat(trade.marketdetails.marketline)) {
                                settledStatus='WIN';
                            } else {
                                settledStatus='LOST';
                            }
                        } else {
                            if (trade.outcome===trade.marketdetails.selectionname) {
                                settledStatus='WIN';
                            } else {
                                settledStatus='LOST';
                            }
                        }
                    }

                    return (
                        <div key={i} className='mt-[20px] w-full md:w-[32%]'>
                            <div className='w-full font-chakra text-white50 font-normal text-[12px] text-center'>{formatTimestamp(trade.tstamp)}</div>

                            <div className='mt-[10px] bg-main text-white font-chakra text-[12px] h-[30px] flex'>
                                <div className='flex-grow pt-[7px] pl-[10px] normal-case truncate text-ellipsis'>{matchTitle}</div>
                                <div 
                                    className='text-lime hover:text-limehover pt-[7px] pr-[5px] cursor-pointer'
                                    onClick={(()=> location.href='/trader?match='+trade.match_id )}
                                >Go to match</div>
                                <div className={`${trade.settled ? 'bg-pink' : 'bg-lightgreen'} border border-solid border-main font-anton text-[14px] text-main p-[8px] pt-[5px]`}>
                                    {trade.settled ? ( 'SETTLED' ) : ( 'OPEN' )}
                                </div>
                            </div>

                            <div className='bg-white border border-solid border-main p-[15px] flex md:min-h-[90px]'>
                                <div><ClubLogo teamName={teams[0]} height={'40px'} width={'40px'} /></div>
                                <div className="flex-grow text-center text-main font-chakra !font-bold text-[14px] pt-[13px]">{teams[0]} <span className='!font-normal'>v</span> {teams[1]}</div>
                                <div><ClubLogo teamName={teams[1]} height={'40px'} width={'40px'} /></div>
                            </div>
                            
                            <div className='bg-white border border-solid border-main py-[15px]'>
                                <div className='pt-[10px] font-anton text-main text-[18px] font-normal text-center md:min-h-[75px]'>{trade.marketdetails.marketname}</div>
                                <div className={
                                    `relative mx-[10px] my-[15px] h-[40px] w-[calc(100%_-_20px)] 
                                    ${trade.settled && trade.outcome!==trade.marketdetails.selectionname && settledStatus==='LOST' ? 'bg-pink' : 'bg-lightgreen'} 
                                    border border-solid border-main flex justify-center`}>
                                    <>
                                    {trade.marketdetails.marketclass==='Win' ? (
                                        <>
                                            <div className='pt-[5px]'>
                                                {trade.marketdetails.marketplayer!=='' ? (
                                                    <PlayerImage teamName={teams} playerName={trade.marketdetails.selectionname} width={'30px'} height={'30px'} />
                                                ) : (
                                                    <ClubLogo teamName={trade.marketdetails.selectionname} height={'30px'} width={'30px'} />
                                                )}
                                            </div>
                                            <div className='font-chakra !font-bold text-[14px] text-center text-main pt-[10px] pl-[10px]'>
                                                {trade.marketdetails.selectionname}
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className='font-chakra !font-bold text-[14px] text-center text-main pt-[10px] pl-[10px]'>
                                               {trade.marketdetails.selectionname}&nbsp;{parseFloat(trade.marketdetails.marketline).toFixed(1)}
                                            </div>
                                        </>
                                    )}
                                    {settledStatus!=='OPEN' && (
                                        <div className='absolute bg-white px-[5px] py-[2px] text-main bottom-[5px] right-[5px]'>{settledStatus}</div>
                                    )}
                                    </>
                                </div>
                            </div>

                            <div className='bg-white border border-solid border-main flex font-chakra text-[12px] text-main'>
                                <div className='w-1/3 border-r-2 border-solid border-main py-[15px] pl-[10px]'>
                                    <div className=''>ODDS</div>
                                    <div className='pt-[6px] !font-bold'>{trade.odds}</div>
                                </div>
                                <div className='w-1/3 border-r-2 border-solid border-main py-[15px] pl-[10px]'>
                                    <div className=''>STAKE</div>
                                    <div className='!font-bold flex gap-2'>
                                        <img src={`${process.env.CDN_ENDPOINT}/assets/single_coin.png`} className='h-[30px]' />
                                        <div className='pt-[6px]'>{trade.stake}</div>
                                    </div>
                                </div>
                                <div className='w-1/3 py-[15px] pl-[10px]'>
                                    <div className=''>RETURNS</div>
                                    <div className='!font-bold flex gap-2 relative'>
                                        <img src={`${process.env.CDN_ENDPOINT}/assets/single_coin.png`} className='h-[30px]' />
                                        {settledStatus==='WIN' && !trade.acknowledge && (<div className="absolute inset-0 pointer-events-none">{generateCoins()}</div>)}
                                        <div className='pt-[6px]'>{trade.return}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>

            <div className='mt-[20px] w-full flex'>
                <div>
                    <div className='font-chakra text-[#18062f] !text-left text-[14px] md:text-[14px]'>
                        <div className="select-wrapper !w-[100%] pt-[2px] pb-[1px]">
                            <select
                                id="perpage"
                                name="perpage"
                                className="dropdown !h-[35px]"
                                value={tradesPerPage}
                                onChange={(e) => setTradesPerPage(e.target.value)}
                            >
                                <option value={10}>Show 10</option>
                                <option value={20}>Show 20</option>
                                <option value={50}>Show 50</option>
                                <option value={100}>Show 100</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className='flex-grow'>
                    <div className='flex justify-end'>
                    <div 
                        className={`h-[40px] w-[40px] border border-solid border-main pt-[12px] pl-[9px] bg-white 
                            ${currentPage === 1 ? "bg-[#9270BE]" : "cursor-pointer"}`}
                        onClick={() => {
                            if (currentPage > 1) {
                                setCurrentPage(currentPage - 1);
                            }
                        }}
                    >
                        <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M5.79421 7.49063L9.46021 11.1566L8.14027 12.4766L2.20057 6.53686L8.14028 0.597162L9.46021 1.91709L5.75334 5.62396L20.4766 5.62416L20.4765 7.49082L5.79421 7.49063Z" fill="#1B062F"/>
                        </svg>
                    </div>
                        {pagination.map((p) => {
                            return (
                                <div 
                                  className={`h-[40px] w-[40px] font-chakra font-normal border border-solid border-main pt-[9px] text-[14px] text-main text-center bg-white 
                                             ${p===currentPage && '!bg-lime'} 
                                             ${p<=maxPage ? 'cursor-pointer' : '!bg-[#9270BE]'}
                                            `}
                                  onClick={() => {
                                        if (p<=maxPage) {
                                            setCurrentPage(p)
                                        }             
                                  }}
                                >
                                    {p}
                                </div>
                            )
                        })}
                        <div 
                            className={`h-[40px] w-[40px] border border-solid border-main pt-[12px] pl-[9px] bg-white 
                                ${currentPage >= maxPage ? "!bg-[#9270BE]" : "cursor-pointer"}`}
                            onClick={() => {
                                if (currentPage < maxPage) {
                                    setCurrentPage(currentPage + 1);
                                }
                            }}
                        >
                            <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M16.2058 6.50937L12.5398 2.84337L13.8597 1.52344L19.7994 7.46314L13.8597 13.4028L12.5398 12.0829L16.2467 8.37603L1.52344 8.37584L1.52346 6.50918L16.2058 6.50937Z" fill="#1B062F"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyTrades;

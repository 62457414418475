import React, { useEffect, useState } from "react";
import Cookies from 'js-cookie';

const WalletDisplay = () => {
  const [showWallet, setShowWallet] = useState(false);
  const [wallet, setWallet] = useState([]);
  const [coins, setCoins] = useState(0);
  const [diamonds, setDiamonds] = useState(0);
  const [lastWon, setLastWon] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchWallet = async () => {
      const session_token = Cookies.get('c8_session_token');
      if (session_token) {
        try {
          const response = await fetch(`${process.env.API_URL}/wallet/${session_token}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': process.env.API_KEY,
              'X-Cricket8-GameID': process.env.TRADER_GAME_ID
            },
          });
          if (response.status === 200) {
            let lw = 0;
            const data = await response.json();
            setWallet(data);

            if (data) {
              if (data.coins) {
                setCoins(data.coins);
              }
              if (data.rubies) {
                setDiamonds(data.rubies);
              }
            }
            setShowWallet(true);
          } else {
            console.error("Failed to fetch wallet data");
          }
        } catch (error) {
          console.error("Error fetching wallet data", error);
        }
      }
      setLoading(false);
    };

    fetchWallet();
  }, []);

  if (loading) {
    return <div></div>;
  }

  return (
    <>
    {showWallet ? (
      <div className="relative w-full h-[56px]">
        <div className="absolute w-full h-[56px] top-0 left-0 bg-radial-gradient opacity-60" />
        <div className='absolute top-0 left-0 w-full px-[15px] py-[8px]'>
          <div className='flex'>
            <div className='w-[50px] border'>
              <img src={wallet.profile_pic} className='h-[40px]' />
            </div>
            <div className="flex-grow font-anton text-white pt-[11px] text-[18px] pl-[10px] truncate">
              {wallet.username}
            </div>

            <div className='flex-end font-anton text-white text-[18px] px-[20px] h-[40px] bg-subhead flex mr-[3px]'>
              <img src={`${process.env.CDN_ENDPOINT}/assets/single_diamond.png`} className='h-[30px] mt-[5px]' />
              <div className='pt-[8px] pl-[10px]'>{diamonds}</div>
            </div>
            <div className='flex-end font-anton text-white text-[18px] px-[20px] h-[40px] bg-subhead flex'>
              <img src={`${process.env.CDN_ENDPOINT}/assets/single_coin.png`} className='h-[30px] mt-[5px]' />
              <div className='pt-[8px] pl-[10px]'>{coins}</div>
            </div>
            <div className='bg-lime h-[40px] font-anton text-main text-center text-[14px] font-normal px-[10px] pt-[11px]'>SHOP</div>

          </div>
        </div>
      </div>
    ) : ( null )} 
    </>
  );
};

export default WalletDisplay;

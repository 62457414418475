"use client";
import { createContext } from 'react';

/**
 * @public
 */
const PresenceContext = 
/* @__PURE__ */ createContext(null);

export { PresenceContext };

import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import WalletDisplay from "../../partials/wallet/wallet-display";
import UpcomingMatches from "./components/upcoming-matches";
import MatchPlay from "./components/match-play";
import MarketTrades from "./components/markets";
import MyTrades from "./components/my-trades";
import { Cookies } from "react-cookie-consent";
import { useUser } from "../../UserContext";

export const Trader = () => {
  const { user, setUser } = useUser();
  const [title, setTitle] = useState("Cricket8.com | Trader");
  const [description, setDescription] = useState(
    "Check this out - Grab your free coins before the launch of our exciting new game! Join the Cricket8.com community today and start collecting your rewards!"
  );
  const [loading, setLoading] = useState(true);
  const [subMenu, setSubMenu] = useState('LOBBY');
  const [tradeCount, setTradeCount] = useState(0);

  const params = new URLSearchParams(window.location.search);
  const [matchId, setMatchId] = useState(false);
  useEffect(() => {
      if (params.has('match')) {
          setMatchId(params.get('match'));
          setSubMenu('MARKETS');
      }
  }, []);

  const [imageLoaded, setImageLoaded] = useState(false);
  const imageRef = useRef(null);

  const getTradeCount = async() => {
      const session_token = Cookies.get('c8_session_token');
      const headers = {
          'Content-Type': 'application/json',
      };
      headers['x-api-key'] = process.env.API_KEY;
      const response = await fetch(`${process.env.API_URL}/trade/mycount/${session_token}`, {
          method: 'get',
          headers: headers
      });

      if (response.ok) {
        const countdata = await response.json();
        setTradeCount(countdata.mycount);
      }
  };

  
  useEffect(() => {
    const observer = new IntersectionObserver(
        entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setImageLoaded(true); 
                    observer.unobserve(entry.target); 
                }
            });
        },
        { threshold: 0.01 } 
    );

    if (imageRef.current) {
        observer.observe(imageRef.current);
    }
    return () => { 
        if (imageRef.current) {
            observer.disconnect();
        }
    };
  }, []);
  const backgroundImage = imageLoaded ? `url(http://dyatkw7msrzcb.cloudfront.net/assets/image49b.webp)` : "none";
  

  useEffect(() => {
    getTradeCount();
    setLoading(false);
  }, []);

  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={`https://${window.location.hostname}/img/spinner_large.png`} />
      </Helmet>

      {!user && matchId && !loading ? (
        <div id="content" className='w-full'>
          <div className='flex w-full justify-center items-center mb-[30px]'>
            <div className='bg-mainH w-[350px] '>
              <div className='mt-[20px] font-chakra text-white case-normal text-[14px] text-center'>You need an active Cricket8 account<br/>to play trader.</div>
              <div className='flex gap-2 p-[10px]'>
                  <div 
                      className='w-1/2 bg-lime hover:bg-limehover cursor-pointer font-anton text-main text-[14px] text-center py-[13px]'
                      onClick={() => location.href='/login' }
                  >LOGIN</div>
                  <div 
                      className='w-1/2 bg-lime hover:bg-limehover cursor-pointer font-anton text-main text-[14px] text-center py-[13px]'
                      onClick={() => location.href='/signup' }
                  >SIGNUP</div>
              </div>
              <div className='m-[10px] bg-mainH p-[10px] flex gap-5'>
                  <div><img height='60px' src={`${process.env.CDN_ENDPOINT}/assets/coinsx4.png`} /></div>
                  <div className='flex-grow font-anton text-[14px] text-white text-center leading-[18px] pt-[10px]'>GET 100 FREE COINS WHEN<br/>YOU CREATE AN ACCOUNT!</div>
                  <div><img height='60px' src={`${process.env.CDN_ENDPOINT}/assets/coinsx3.png`} /></div>
              </div>
              <div className='h-[10px] w-full' />
            </div>
          </div>
        </div>
      ) : (

      <div id="content" className="min-h-[50vh] !p-0 mb-[100px]">
        {!loading && !matchId && <WalletDisplay />}

        {!loading && matchId && <MatchPlay matchId={matchId} />}


        {/* trader sub-menu */}
        <div className='flex w-full font-anton text-white text-[18px] bg-radial-gradient h-[50px] mb-[30px]'>
            {!matchId ? (
              <div className={`relative w-[90px] h-[50px] cursor-pointer ${subMenu==='LOBBY' ? ('text-white') : ('text-white60')}`} onClick={() => setSubMenu('LOBBY')} >
                  <div className='mt-[10px] text-center'>LOBBY</div>
                  <div className={`absolute bottom-[0px] h-[4px] bg-lime w-[90px] ${subMenu!=='LOBBY' && ('hidden')}`}></div>
              </div>
            ) : (
              <div className={`relative w-[90px] h-[50px] cursor-pointer ${subMenu==='MARKETS' ? ('text-white') : ('text-white60')}`} onClick={() => setSubMenu('MARKETS')} >
                  <div className='mt-[10px] text-center'>MARKETS</div>
                  <div className={`absolute bottom-[0px] h-[4px] bg-lime w-[90px] ${subMenu!=='MARKETS' && ('hidden')}`}></div>
              </div>
            )}
            <div className={`relative w-[90px] h-[50px] cursor-pointer ${subMenu==='MY TRADES' ? ('text-white') : ('text-white60')}`} onClick={() => setSubMenu('MY TRADES')}>
                <div className='mt-[10px] text-center'>MY TRADES</div>
                <div className={`absolute bottom-[0px] h-[4px] bg-lime w-[90px] ${subMenu!=='MY TRADES' && ('hidden')}`}></div>
                <div className={`absolute bottom-[-10px] left-[35px] bg-pink text-anton text-[12px] px-[8px] py-[2px] text-white ${tradeCount==0 && 'hidden'}`}>{tradeCount}</div>
            </div>
            {/*<div className={`relative w-[90px] h-[50px] cursor-pointer ${subMenu==='TABLES' ? ('text-white') : ('text-white60')}`} onClick={() => setSubMenu('TABLES')}>
                <div className='mt-[10px] text-center'>TABLES</div>
                <div className={`absolute bottom-[0px] h-[4px] bg-lime w-[90px] ${subMenu!=='TABLES' && ('hidden')}`}></div>
            </div>*/}
            <div className={`relative w-[90px] h-[50px] cursor-pointer ${subMenu==='RULES' ? ('text-white') : ('text-white60')}`} onClick={() => setSubMenu('RULES')}>
                <div className='mt-[10px] text-center'>RULES</div>
                <div className={`absolute bottom-[0px] h-[4px] bg-lime w-[90px] ${subMenu!=='RULES' && ('hidden')}`}></div>
            </div>
        </div>

        {subMenu==='LOBBY' ? (
            <>
            <div className='mt-[50px] mb-[20px] px-[16px] md:px-0 relative'>
                <div    
                    ref={imageRef}    
                    className="bg-cover bg-center h-full w-full pb-[20px] text-center"
                    style={{ backgroundImage: backgroundImage }}
                >
                    <div className='text-lime font-anton text-[30px] font-normal pt-[40px]'>TRADER</div>
                    <div className='text-white font-chakra text-[16px] normal-case pt-[15px]'>Trade live matches on winner, run<br/>lines, fancies and more. Find your<br/>edge and press for profit.</div>
                    <div className='bg-lime text-main font-anton text-[14px] px-[10px] pt-[3px] pb-[1px] mt-[10px] w-fit mx-auto'>HOW TO PLAY</div>
                </div>
                <div className='absolute top-[-10px] left-1/2 transform -translate-x-1/2 '>
                    <div className="bg-lime w-[40px] h-[40px] px-[5px] pt-[5px]">
                      <svg fill="#1B062F" width="30px" height="30px" viewBox="0 0 256 256" id="Flat" xmlns="http://www.w3.org/2000/svg">
                        <path d="M241.2793,70.4541a19.8817,19.8817,0,0,0-20.88184-2.874L173.1582,88.57617,145.4834,38.76074a20,20,0,0,0-34.9668-.001L82.8418,88.57617l-47.249-21.001A20.00018,20.00018,0,0,0,8.002,90.42676l25.44434,108.333a20.06608,20.06608,0,0,0,24.86523,14.68261,261.8952,261.8952,0,0,1,139.33008-.01367A20.012,20.012,0,0,0,222.5,198.75488L247.98926,90.43652A19.88333,19.88333,0,0,0,241.2793,70.4541ZM56.81055,193.27246l-.002-.00879.00293.00977Zm143.27539-4.04a286.03693,286.03693,0,0,0-144.22071.0127L33.207,92.7793l43.23145,19.21386a19.90073,19.90073,0,0,0,25.60644-8.5625L128,56.71l25.957,46.72266a19.8994,19.8994,0,0,0,25.60547,8.56054l43.21875-19.209Zm-28.14844-34.373a11.99046,11.99046,0,0,1-13.16113,10.71386,302.37638,302.37638,0,0,0-61.55274,0,12,12,0,1,1-2.44726-23.875,326.48954,326.48954,0,0,1,66.44726,0A12.00021,12.00021,0,0,1,171.9375,154.85938Z"/>
                      </svg>
                    </div>
                </div>
                <div className='w-full p-[20px] text-center bg-mainG font-chakra normal-case text-[14px] font-normal leading-[1.4]'>
                    <span className='font-bold text-[#9356ea]'>Play for fun:</span> Cricket8 is not a gambling<br/>company and you cannot win real money.
                </div>
            </div>

            <div className='bg-[#2ef4e] py-[20px]'>
                <UpcomingMatches maxMatches={9} />
            </div>

            </>
        ) : subMenu==='MARKETS' ? (
          <>
            <MarketTrades matchId={matchId} updateTradeCount={getTradeCount} />
          </>
        ) : subMenu==='MY TRADES' ? (
          <>
            <MyTrades />
          </>
        ): (
            null
        )}

      </div>
      )}
    </div>
  );
};

export default Trader;

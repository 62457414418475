import React, { useEffect, useState } from "react";
import MarketDisplay from "./market-display";
import Modal from 'react-modal';
import BetSlip from "./bet-slip";


const MarketTrades = ({ matchId, updateTradeCount }) => {
    const [liveBat, setLiveBat] = useState('');
    const [score, setScore] = useState(['-','-']);
    const [teams, setTeams] = useState(["",""]);
    const [abbrTeams, setAbbrTeams] = useState(["",""]);
    const [matchTitle, setMatchTitle] = useState('');

    const [marketData, setMarketData] = useState([]);
    const [marketPrice, setMarketPrice] = useState([]);

    const [isBetSlipOpen, setisBetSlipOpen] = useState(false);
    const [betslipi, setBetslipi] = useState(0);
    const [betslipmarket, setBetslipmarket] = useState([]);
    const [betslipmatchingMarketPrice, setBetslipMatchingMarketPrice] = useState([]);
    const [betslipselectionId, setBetslipSelectionId] = useState(null);

    const allowedMarketIds = ["10", "12", "16", "410", "510", "210", "710", "760", "610", "340", "342", "344", "415", "25", "30", "35", "45", "50", "55", "60", "216", "217", "220", "230", "270", "280", "455", "460", "630", "635", "720", "730", "621", "40", "70", "75", "80", "85", "86", "88", "90", "94", "96", "240", "250", "260", "855", "865", "870", "860", "815", "922", "930"];

    const handleSelectionClick = (index, selectionId) => {
        setBetslipi(index);
        setBetslipSelectionId(selectionId);
        setisBetSlipOpen(true);
    };
    


    const wsUrl = process.env.FEED_URL; 
    const apiKey = process.env.DECIMAL_API_KEY;

    async function getShortName(teamname) {
        const res = await fetch(process.env.API_URL+'/teams/shortname/'+teamname, {
            method: 'GET', 
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': process.env.API_KEY
            },
            });
            const data = await res.json();
            return data.shortname;
    }

    async function subscribeToMatch(match_id) {
        let socket;
        if (process.env.ENVIRONMENT!=='dev') {
          socket = new WebSocket(`${wsUrl}`);
        } else {
          socket = new WebSocket(`${wsUrl}/${match_id}/${apiKey}/APIFEED-${match_id}`);
        }
        socket.addEventListener('open', function open() {
          const message = JSON.stringify({
            feed_filter: ['market_update', 'market_price_update', 'scorecard'],
            msg_type: "feed_subscription", 
            feed_id: match_id,
          });
          socket.send(message);
        });
      
        socket.addEventListener('message', async function incoming(data) {
            const parsedData = JSON.parse(data.data);
            if (parsedData.scorecard) {
                const matchParts = parsedData.scorecard.description.split(',');
                setMatchTitle(matchParts.length >= 3 ? matchParts[1].trim() : '');
                setTeams(matchParts[0].split(" v "));
            }
            if (parsedData.market_update) {
                setMarketData((prevMarkets) => {
                    const filteredMarkets = parsedData.market_update.markets
                    .filter(
                        (market) =>
                            market.priority !== 0 &&
                            market.in_play === 'Yes' &&
                            market.settled_status==='UNSETTLED' &&
                            market.market_type!=="Windex" &&
                            allowedMarketIds.includes(market.market_type_id)
                    );
                    const newMarkets = filteredMarkets.filter(
                        (newMarket) => !prevMarkets.some((market) => market.market_id === newMarket.market_id)
                    );
                    newMarkets.sort((a, b) => a.display_order - b.display_order);
                    const updatedMarkets = [...prevMarkets, ...newMarkets].sort((a, b) => a.display_order - b.display_order);
                    return updatedMarkets;
                });
            }
        
            if (parsedData.market_price_update) {
                setMarketPrice((prevMarketPrices) => {
                    let updatedMarketPrices = [...prevMarketPrices];
            
                    parsedData.market_price_update.markets
                        .forEach((newMarketPrice) => {
                            const existingIndex = updatedMarketPrices.findIndex(
                                (market) => market.market_id === newMarketPrice.market_id
                            );
                
                            if (existingIndex !== -1) {
                                // If display is 0, remove this market from marketPrice
                                if (newMarketPrice.display === 0) {
                                    updatedMarketPrices.splice(existingIndex, 1);
                                } else {
                                    // Otherwise, update the existing market data
                                    updatedMarketPrices[existingIndex] = {
                                        ...updatedMarketPrices[existingIndex],
                                        ...newMarketPrice,
                                    };
                                }
                            } else {
                                // Add new market if display is 1
                                if (newMarketPrice.display === 1) {
                                    updatedMarketPrices.push(newMarketPrice);
                                }
                            }
                    });
            
                    return updatedMarketPrices;
                });
            }
        
            });
            socket.addEventListener('error', function error(error) {
            //console.error('WebSocket error:', error);
            });
            socket.addEventListener('close', function close(event) {
            //console.log('Disconnected from the WebSocket server:', event);
            //console.log(`Close code: ${event.code}, reason: ${event.reason}`);
            });
    }

    useEffect(() => {
        subscribeToMatch(matchId);
    }, [matchId]);


    return (
        <div className="w-full mt-2">

            <div className='my-[10px] m-[15px] md:m-0 font-chakra text-[14px] font-normal'>
                {
                    marketPrice.filter(priceMarket => 
                        marketData.some(market => market.market_id === priceMarket.market_id)
                    ).length
                } markets in play.
            </div>

            {marketData.map((market, i) => {
                const matchingMarketPrice = marketPrice.find(
                    (priceMarket) => priceMarket.market_id === market.market_id
                );
                
                return (
                    <MarketDisplay key={i} i={i} market={market} matchingMarketPrice={matchingMarketPrice} onSelectionClick={handleSelectionClick} />
                )

            })}


            <Modal 
                isOpen={isBetSlipOpen} 
                onRequestClose={() => setisBetSlipOpen(false)} 
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: '100%',
                        maxWidth: '400px',
                        background: '#1b062f',
                        textAlign: 'left',
                        color: '#fff',
                        padding: '0'
                    }
                }}
                contentLabel="Betting Slip"
                ariaHideApp={false}
            >
                <div>
                    {isBetSlipOpen && (
                        <BetSlip 
                            i={betslipi} 
                            matchId={matchId} 
                            teams={teams} 
                            market={marketData[betslipi]} 
                            matchMarketPrice={marketPrice.find(
                                (priceMarket) => priceMarket.market_id === marketData[betslipi]?.market_id
                            )} 
                            selectionId={betslipselectionId}
                            closeModal={() => {
                                setisBetSlipOpen(false);
                                updateTradeCount();
                            }} 
                        />
                    )}
                </div>
            </Modal>


        </div>
    );
    
};

export default MarketTrades;

import React, { useEffect, useState } from "react";
import ClubLogo from "../../ClubLogo";
import PlayerImage from "../../PlayerImg";
import MarketDisplay from "./market-display";
import { Cookies } from "react-cookie-consent";

const BetSlip = ({ i, matchId, teams, market, matchMarketPrice, selectionId, closeModal }) => {

    const [stake, setStake] = useState(100);
    const [returns, setReturns] = useState(0);
    const [factor, setFactor] = useState(0)

    const [placed, setPlaced] = useState({odds: 0, stake: 0, returns: 0, marketname: '', marketline: '', marketplayer: '', marketteam: '', marketclass: '', selectionname: '' })
    const [submissionError, setSubmissionError] = useState(null);

    const [betAccepted, setBetAccept] = useState(false);
    const [submittingTrade, setSubmittingTrade] = useState(false);

    const addToStake = ( char ) => {
        let currentStake=stake.toString();
        if (char==='bs' ) {
            setStake(currentStake.substring(0,currentStake.length-1));
        } else {
            currentStake=currentStake+char;
            setStake(currentStake);
        }
    }

    useEffect(() => {
        let ret=Math.floor(stake*factor);
        setReturns(ret);
    }, [stake, factor]);

    useEffect(() => {
        if (!betAccepted) {
            if ((!matchMarketPrice || !market)) {
                closeModal(); // Close modal if data is missing
            } else {
                setFactor(matchMarketPrice.selections[selectionId].recommended_price);
            }
        }
    }, [market, matchMarketPrice, i, selectionId]);


    const handleTrade = () => {
        setSubmissionError(null);
        setSubmittingTrade(true);
        const SubmitTrade = async() => {
            const session_token = Cookies.get('c8_session_token');
            const headers = {
                'Content-Type': 'application/json',
            };
            headers['x-api-key'] = process.env.API_KEY;
            const data = {
                matchId: matchId,
                market_id: market.market_id,
                selection_id: selectionId,
                odds: matchMarketPrice.selections[selectionId].recommended_price,
                stake: stake,
                return: returns
            }
            const marketname=market.name;
            const marketline=matchMarketPrice.line;
            const marketplayer=market.player;
            const marketteam=market.team;
            const marketclass=market.class;
            const selectionname=matchMarketPrice.selections[selectionId].name;

            const response = await fetch(`${process.env.API_URL}/trade/submit/${session_token}`, {
                method: 'post',
                headers: headers,
                body: JSON.stringify({data: data, marketdetails: {marketname: marketname, marketline: marketline, marketplayer: marketplayer, marketteam: marketteam, marketclass: marketclass, selectionname: selectionname}})
            });
            if (response.ok) {
                const betdata = await response.json();
                setPlaced({
                    odds: betdata.data.odds,
                    stake: betdata.data.stake, 
                    returns: betdata.data.confirmedRtn,
                    marketname: marketname, 
                    marketline: marketline, 
                    marketplayer: marketplayer, 
                    marketteam: marketteam, 
                    marketclass: marketclass, 
                    selectionname: selectionname 
                });
                setBetAccept(true);
            } else {
                const betdata = await response.json();
                setSubmissionError(betdata.message);
            }
            setSubmittingTrade(false);
        };
        if (stake>=100) {
            SubmitTrade();
        } else {
            setSubmissionError('Minimum 100 coin bet required');
            setSubmittingTrade(false);
        }
    };

    return ( 
        <>
        {betAccepted ? (
            <div className='mt-0 p-0'>
                <div className='w-full bg-[#652DAF] h-[100px] flex flex-col items-center justify-center'>
                        <div className='w-[30px] h-[30px] bg-lightgreen text-main flex items-center justify-center'>
                            <svg width="25" height="18" viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M23.9138 2.1013L8.9138 17.1013C8.82673 17.1885 8.72334 17.2576 8.60953 17.3048C8.49572 17.352 8.37372 17.3763 8.25052 17.3763C8.12732 17.3763 8.00532 17.352 7.89151 17.3048C7.7777 17.2576 7.67431 17.1885 7.58724 17.1013L1.02474 10.5388C0.848827 10.3629 0.75 10.1243 0.75 9.87552C0.75 9.62674 0.848827 9.38815 1.02474 9.21224C1.20065 9.03633 1.43924 8.9375 1.68802 8.9375C1.9368 8.9375 2.17539 9.03633 2.3513 9.21224L8.25052 15.1126L22.5872 0.77474C22.7632 0.598826 23.0017 0.5 23.2505 0.5C23.4993 0.5 23.7379 0.598826 23.9138 0.77474C24.0897 0.950653 24.1885 1.18924 24.1885 1.43802C24.1885 1.6868 24.0897 1.92539 23.9138 2.1013Z" fill="#18062F"/>
                            </svg>
                        </div>
                        <div className='mt-[5px] font-anton text-white text-[18px] font-normal'>TRADE PLACED</div>
                </div>
                <div className='bg-white border border-solid border-main p-[15px] flex'>
                    <div><ClubLogo teamName={teams[0]} height={'50px'} width={'50px'} /></div>
                    <div className="flex-grow text-center text-main font-chakra !font-bold text-[14px] pt-[5px]">{teams[0]} <span className='!font-normal'>v</span> {teams[1]}</div>
                    <div><ClubLogo teamName={teams[1]} height={'50px'} width={'50px'} /></div>
                </div>
                <div className='bg-white border border-solid border-main py-[15px]'>
                    <div className='pt-[10px] font-anton text-main text-[18px] font-normal text-center'>{placed.marketname}</div>
                    <div className='mx-[10px] my-[15px] h-[40px] w-[calc(100%_-_20px)] bg-lightgreen border border-solid border-main flex justify-center'>
                        {market.class==='Win' ? (
                            <>
                                <div className='pt-[5px]'>
                                    {placed.marketplayer!=='' ? (
                                        <PlayerImage teamName={teams} playerName={placed.selectionname} width={'30px'} height={'30px'} />
                                    ) : (
                                        <ClubLogo teamName={placed.selectionname} height={'30px'} width={'30px'} />
                                    )}
                                </div>
                                <div className='font-chakra !font-bold text-[14px] text-center text-main pt-[10px] pl-[10px]'>
                                    {placed.selectionname}
                                </div>
                            </>
                        ) : (
                            <>
                                <div className='font-chakra !font-bold text-[14px] text-center text-main pt-[10px] pl-[10px]'>
                                    {placed.selectionname}&nbsp;{parseFloat(placed.marketline).toFixed(1)}
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className='bg-white border border-solid border-main flex font-chakra text-[12px] text-main'>
                    <div className='w-1/3 border-r-2 border-solid border-main py-[15px] pl-[10px]'>
                        <div className=''>ODDS</div>
                        <div className='pt-[7px] !font-bold'>{placed.odds}</div>
                    </div>
                    <div className='w-1/3 border-r-2 border-solid border-main py-[15px] pl-[10px]'>
                        <div className=''>STAKE</div>
                        <div className='!font-bold flex gap-2'>
                            <img src={`${process.env.CDN_ENDPOINT}/assets/single_coin.png`} className='h-[30px]' />
                            <div className='pt-[7px]'>{placed.stake}</div>
                        </div>
                    </div>
                    <div className='w-1/3 py-[15px] pl-[10px]'>
                        <div className=''>RETURNS</div>
                        <div className='!font-bold flex gap-2'>
                            <img src={`${process.env.CDN_ENDPOINT}/assets/single_coin.png`} className='h-[30px]' />
                            <div className='pt-[7px]'>{placed.returns}</div>
                        </div>
                    </div>
                </div>
                <div className='bg-white border border-solid border-main flex font-chakra text-[12px] text-main p-[10px]'>
                    <div 
                        className='w-full h-[40px] bg-[#D2FF00] hover:bg-[#c2e809] text-main font-anton text-[16px] pt-[9px] text-center cursor-pointer'
                        onClick={() => {closeModal()}}>
                            DONE
                    </div>

                </div>
            </div>
        ) : (
            <div className='mt-0 p-0'>
                <MarketDisplay i={i} market={market} matchingMarketPrice={matchMarketPrice} onSelectionClick={null} isBetSlip={true} selectionId={selectionId} closeModal={closeModal} />
                <div className='bg-white border border-solid border-main p-[15px]'>

                    <div className='flex'>
                        <div className='font-anton text-[14px] font-normal text-main pr-[10px] pt-[13px]'>STAKE:</div>
                        <div className='flex-grow h-[40px] bg-lightgreen20 border border-solid border-main font-chakra text-[14px] font-normal px-[13px]'>
                            <div className='flex text-main'>
                                <img src={`${process.env.CDN_ENDPOINT}/assets/single_coin.png`} className='h-[30px] mt-[4px]' />
                                <div className='flex-grow pt-[9px] pl-[10px]'>{stake}</div>
                                <div className='text-right pt-[13px] cursor-pointer' onClick={() => setStake('')}>
                                    <svg 
                                        fill="#1B062F" 
                                        height="14px" 
                                        width="14px" 
                                        viewBox="0 0 460.775 460.775"
                                    >
                                        <path d="M285.08,230.397L456.218,59.27c6.076-6.077,6.076-15.911,0-21.986L423.511,4.565c-2.913-2.911-6.866-4.55-10.992-4.55
                                            c-4.127,0-8.08,1.639-10.993,4.55l-171.138,171.14L59.25,4.565c-2.913-2.911-6.866-4.55-10.993-4.55
                                            c-4.126,0-8.08,1.639-10.992,4.55L4.558,37.284c-6.077,6.075-6.077,15.909,0,21.986l171.138,171.128L4.575,401.505
                                            c-6.074,6.077-6.074,15.911,0,21.986l32.709,32.719c2.911,2.911,6.865,4.55,10.992,4.55c4.127,0,8.08-1.639,10.994-4.55
                                            l171.117-171.12l171.118,171.12c2.913,2.911,6.866,4.55,10.993,4.55c4.128,0,8.081-1.639,10.992-4.55l32.709-32.719
                                            c6.074-6.075,6.074-15.909,0-21.986L285.08,230.397z"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='mt-[10px] flex font-chakra text-[12px] font-normal text-main gap-2'>
                        {/* <div className='h-[30px] bg-[#efeef0] p-[9px] flex-grow text-center cursor-pointer' onClick={() => setStake(10)}>10</div> */}
                        <div className='h-[30px] bg-[#efeef0] p-[6px] flex-grow text-center cursor-pointer' onClick={() => setStake(100)}>100</div>
                        <div className='h-[30px] bg-[#efeef0] p-[6px] flex-grow text-center cursor-pointer' onClick={() => setStake(1000)}>1,000</div>
                        <div className='h-[30px] bg-[#efeef0] p-[6px] flex-grow text-center cursor-pointer' onClick={() => setStake(10000)}>10,000</div>
                    </div>

                    <div className='mt-[10px] font-chakra text-[14px] font-normal text-main'>
                        <div className='flex'>
                            <div className='h-[40px] w-[52px] text-center pt-[10px] bg-[#efeef0] border border-solid border-main cursor-pointer' onClick={() => addToStake(1)}>1</div>
                            <div className='h-[40px] w-[52px] text-center pt-[10px] bg-[#efeef0] border border-solid border-main border-l-0 cursor-pointer' onClick={() => addToStake(2)}>2</div>
                            <div className='h-[40px] w-[52px] text-center pt-[10px] bg-[#efeef0] border border-solid border-main border-l-0 cursor-pointer' onClick={() => addToStake(3)}>3</div>
                            <div className='h-[40px] w-[52px] text-center pt-[10px] bg-[#efeef0] border border-solid border-main border-l-0 cursor-pointer' onClick={() => addToStake(4)}>4</div>
                            <div className='h-[40px] w-[52px] text-center pt-[10px] bg-[#efeef0] border border-solid border-main border-l-0 cursor-pointer' onClick={() => addToStake(5)}>5</div>
                            <div className="h-[40px] w-[52px] flex items-center justify-center bg-[#efeef0] border border-solid border-main border-l-0 cursor-pointer" onClick={() => addToStake("bs")}>
                                <svg 
                                    fill="#000000" 
                                    height="14px" 
                                    width="18px" 
                                    viewBox="0 0 44.18 44.18"
                                >
                                    <g>
                                        <path d="M10.625,5.09L0,22.09l10.625,17H44.18v-34H10.625z M42.18,37.09H11.734l-9.375-15l9.375-15H42.18V37.09z"/>
                                        <polygon points="18.887,30.797 26.18,23.504 33.473,30.797 34.887,29.383 27.594,22.09 34.887,14.797 33.473,13.383 26.18,20.676 
                                            18.887,13.383 17.473,14.797 24.766,22.09 17.473,29.383 	"/>
                                    </g>
                                </svg>
                            </div>
                        </div>
                        <div className='flex'>
                            <div className='h-[40px] w-[52px] text-center pt-[10px] bg-[#efeef0] border border-solid border-main border-t-0 cursor-pointer' onClick={() => addToStake(6)}>6</div>
                            <div className='h-[40px] w-[52px] text-center pt-[10px] bg-[#efeef0] border border-solid border-main border-l-0 border-t-0 cursor-pointer' onClick={() => addToStake(7)}>7</div>
                            <div className='h-[40px] w-[52px] text-center pt-[10px] bg-[#efeef0] border border-solid border-main border-l-0 border-t-0 cursor-pointer' onClick={() => addToStake(8)}>8</div>
                            <div className='h-[40px] w-[52px] text-center pt-[10px] bg-[#efeef0] border border-solid border-main border-l-0 border-t-0 cursor-pointer' onClick={() => addToStake(9)}>9</div>
                            <div className='h-[40px] w-[52px] text-center pt-[10px] bg-[#efeef0] border border-solid border-main border-l-0 border-t-0 cursor-pointer' onClick={() => addToStake(0)}>0</div>
                        </div>
                    </div>
                    <div className='mt-[10px] font-chakra text-[14px] font-normal text-main flex gap-2'>
                        <div className='flex-grow bg-lightgreen20 h-[40px]'>
                            <div className="flex">
                                <div className='pl-[10px] pt-[8px] pr-[5px]'>Returns:</div>
                                <div><img src={`${process.env.CDN_ENDPOINT}/assets/single_coin.png`} className='h-[30px] mt-[3px]' /></div>
                                <div className='font-bold pl-[5px] pt-[8px]'>{returns}</div>
                            </div>
                        </div>


                        {!submittingTrade ? (
                            <div 
                                className='flex-grow bg-[#D2FF00] hover:bg-[#c2e809] cursor-pointer h-[40px] font-anton text-center text-[16px] pt-[9px] cursor-pointer'
                                onClick={(() => handleTrade() )}    
                            >
                                Submit Trade 
                            </div>
                        ) : (
                            <div className='flex-grow h-[40px] bg-[#d2ff00] items-center justify-center text-main flex'>
                                <div className="submit-indicator-solid"></div>
                                <div className="submit-indicator-solid ml-[5px]"></div>
                                <div className="submit-indicator-solid ml-[5px]"></div>
                            </div>
                        )}
                    </div>
                    {submissionError!==null && (
                        <div className='text-right font-chakra font-normal text-[12px] text-pink pt-[5px]'>{submissionError}</div>
                    )}
                </div>
            </div>
        )}
        </>
    )
};

export default BetSlip;

import React, { useEffect, useState } from "react";

const TraderCommentary = ({ commentary, innsNow, batNow, teams, closeModal }) => {
    const [displayInnings, setDisplayInnings] = useState(innsNow);

    function generateInningsOrder(teams, currentBatting, currentInnings) {
        const team1 = teams[0];
        const team2 = teams[1];
        const firstBatting = (currentInnings === 1 || currentInnings === 3) 
            ? currentBatting 
            : teams.find(team => team !== currentBatting);
    
        const secondBatting = firstBatting === team1 ? team2 : team1;
        const inningsOrder = [
            { innings: 1, team: firstBatting },
            { innings: 2, team: secondBatting },
            { innings: 3, team: firstBatting },
            { innings: 4, team: secondBatting }
        ];
        return inningsOrder;
    }
    const inningsArray = generateInningsOrder(teams, batNow, innsNow);
    let sel=[];
    for (let i=4; i>0; i=i-1) {
        if (commentary.commentaries["inns"+i]!==null && commentary.commentaries["inns"+i]!==undefined) {
            sel.push({idx: i, name: inningsArray[i-1]});
        }
    }
    let currentOver=99999;

    return ( 
        <>
            <div className='w-100 bg-modal'>
                <div className='relative flex justify-center'>
                    <div className='fit-content text-center bg-white text-main font-anton text-[14px] p-[5px]'>
                        MATCH COMMENTARY
                    </div>
                    <div className='absolute right-[5px] top-[3px] text-right cursor-pointer' onClick={() => closeModal()}>
                        <svg 
                            fill="#fff" 
                            height="10px" 
                            width="10px" 
                            viewBox="0 0 460.775 460.775"
                        >
                            <path d="M285.08,230.397L456.218,59.27c6.076-6.077,6.076-15.911,0-21.986L423.511,4.565c-2.913-2.911-6.866-4.55-10.992-4.55
                                c-4.127,0-8.08,1.639-10.993,4.55l-171.138,171.14L59.25,4.565c-2.913-2.911-6.866-4.55-10.993-4.55
                                c-4.126,0-8.08,1.639-10.992,4.55L4.558,37.284c-6.077,6.075-6.077,15.909,0,21.986l171.138,171.128L4.575,401.505
                                c-6.074,6.077-6.074,15.911,0,21.986l32.709,32.719c2.911,2.911,6.865,4.55,10.992,4.55c4.127,0,8.08-1.639,10.994-4.55
                                l171.117-171.12l171.118,171.12c2.913,2.911,6.866,4.55,10.993,4.55c4.128,0,8.081-1.639,10.992-4.55l32.709-32.719
                                c6.074-6.075,6.074-15.909,0-21.986L285.08,230.397z"/>
                        </svg>
                    </div>
                </div>

                <div className='p-[10px] max-h-[575px] overflow-y-auto'>
                    <div className='font-chakra text-[#18062f] !text-left text-[12px] md:text-[14px]'>
                        <div className="select-wrapper !w-[100%]">
                            <select
                                id="comminns"
                                name="comminns"
                                className="dropdown !h-[35px]"
                                onChange={(e) => setDisplayInnings(Number(e.target.value))}
                            >
                                {sel.map((selection, index) => (
                                    <option key={index} value={selection.idx}>
                                        {selection.name.team} - Innings {selection.name.innings}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className='mt-[10px]'>
                        {commentary.commentaries["inns"+displayInnings].slice().reverse().map((comm, i) => {

                            let showOverHead=false;
                            if (Math.floor(comm.ball)!==currentOver) {
                                currentOver=Math.floor(comm.ball);
                                showOverHead=true;
                            }
                            return (
                            <>
                                {showOverHead && (
                                    <div className='mt-[10px] w-full bg-lime p-[5px] text-main font-anton text-[14px] border border-solid border-main'>Over {currentOver+1}</div>
                                )}

                                <div className='flex font-chakra text-[14px] font-normal normal-case bg-white text-main border border-solid border-main border-t-0'>
                                    <div className='p-[5px] w-[50px] font-bold border-r border-solid border-main'>{comm.ball.toFixed(1)}</div>
                                    <div className='p-[5px] flex-grow'>{comm.commentary}</div>
                                </div>
                            
                            </>
                            )
                        })}
                    </div>
                </div>
            </div>

        </>
    )
};

export default TraderCommentary;
